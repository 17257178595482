import { registerComponent } from "turbo-mount/react"
import { turboMount } from "./turbo-mount"

// to register a component use:
// registerComponent(turboMount, "Hello", Hello); // where Hello is the imported the component
import SurveyComponent from "./SurveyComponent";
registerComponent(turboMount, "SurveyComponent", SurveyComponent);

// to override the default controller use:
// registerComponent(turboMount, "Hello", Hello, HelloController); // where HelloController is a Stimulus controller extended from TurboMountController

// If you want to automatically register components use:
// import { registerComponents } from "turbo-mount/registerComponents/react";
// const controllers = import.meta.glob("/controllers/**/*_controller.js", { eager: true });
// const components = import.meta.glob("/components/**/*.jsx", { eager: true });
// registerComponents({ turboMount, components, controllers });

