import axios from 'axios'

const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'};

const httpClient = axios.create({
  headers: {
    common: {
      'X-CSRF-Token': token.content
    }
  },
  withCredentials: true
});

export default httpClient;
